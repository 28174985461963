.textarea-wrapper {
  position: relative;
  width: 100%;
}

.textarea {
  display: block;
  border: none;
  padding: 20px 17px;
  padding-bottom: 40px;
  border-radius: 17px;
  box-shadow: 0px 6px 36px rgba(21, 23, 23, 0.05);
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  height: 140px;
  resize: none;
  box-sizing: border-box;
}

.textarea-helper {
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #cad0d0;
}
