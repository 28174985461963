.wrap {
  width: 260px;
  height: 46px;
  margin-bottom: 30px;
  position: relative;
}

.select {
  border-radius: 17px;
  padding: 13px 16px;
}

.value-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}

.dropdown {
  position: absolute;
  width: 240px;
  bottom: -100;
  right: 0;
  border-radius: 17px;
  padding: 10px;
  margin-top: 2px;
}

.dropdown-value-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  padding: 11.5px 9px;
  cursor: pointer;
}

.optionWrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.text {
  color: #151717;
  font-size: 16px;
  line-height: 18px;
  font-family: "Plus Jakarta Sans";

}