.privacy-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    color: #151717;
    margin-bottom: 10px;
    font-family: "Plus Jakarta Sans";
}

.privacy-subtitle {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #151717;
    margin-bottom: 24px;
    font-family: "Plus Jakarta Sans";
}

.privacy-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #4b5253;
    margin-bottom: 20px;
    font-family: "Plus Jakarta Sans";
}

.privacy-text-l {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #4b5253;
    margin-bottom: 20px;
    margin-left: 20px;
    font-family: "Plus Jakarta Sans";
}

@media (max-width: 600px) {
    .privacy {
        padding-top: 100px;
    }

    .privacy-title {
        font-weight: 700;
        font-size: 30px;
        line-height: 46px;
        color: #151717;
        margin-bottom: 30px;
        font-family: "Plus Jakarta Sans";
    }

    .privacy-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #4b5253;
        margin-bottom: 20px;
        font-family: "Plus Jakarta Sans";
    }
}