.footer {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  border-top: 1px solid rgba(60, 182, 193, 0.2);
}

.footer-title {
  font-size: 16px;
  line-height: 20px;
  color: #889293;
  font-weight: 500;
  text-align: center;
}

.privacy-wrap {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.privacy-link {
  font-size: 14px;
  line-height: 17.64px;
  font-weight: 500;
  color: #889293;
  transition: 0.3s;
}

.privacy-link:hover {
  color: #3cb6c1;
}