.App {
  /* width: 80%; */
  /* background: linear-gradient(180deg, #f9fdff 0%, #d5ebf7 100%) no-repeat; */
  margin: 0 auto;
}

.App-top {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* height: 100vh; */
}

.App-top-wrapper {
  flex: 1;
}

@media (max-width: 600px) {
  .App-top {
    height: auto;
  }
}
