.top-wrapper {
  display: flex;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  overflow: hidden;
}

.top-container {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.top-item {
  flex: 1;
}

.top-info {
  /* padding-top: 64px; */
}

.top-info-description {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #4b5253;
  margin-bottom: 10px;
}

.top-info-description-last {
  margin-bottom: 40px;
}

.top-info-help {
  font-weight: 700 !important;
  color: #151717;
  margin-bottom: 40px;
}

.top-info-title {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  color: #151717;
}

.top-info-buttons {
  display: flex;
  align-items: center;
}

.top-info-buttons-item {
  margin-right: 28px;
}

.top-info-buttons-item img {
  width: 100%;
}

.top-info-buttons-item:last-child {
  margin-right: 0;
}

.top-info-preview {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.top-info-preview-wrapper {
  width: 80%;
}

.top-info-preview-wrapper img {
  width: 120%;
  object-fit: cover;
  position: relative;
  /* right: -100px; */
}

@media (max-width: 1300px) {
  .top-wrapper {
    padding-top: 30px;
  }

  .top-info-preview-wrapper {
    width: 90%;
  }

  .top-info-preview-wrapper img {
    /* right: -50px; */
  }
}

@media (max-width: 900px) {
  .top-info {
    padding-top: 20px;
    flex: 3;
  }

  .top-info-preview {
    flex: 2;
  }

  .top-info-preview-wrapper {
    width: 100%;
  }

  .top-info-preview-wrapper img {
    width: 150%;
  }
}

@media (max-width: 800px) {
  .top-info {
    flex: 3;
  }

  .top-info-preview {
    flex: 2;
  }

  .top-info-description {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #151717;
    margin-bottom: 40px;
  }

  .top-info-title {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    color: #151717;
  }

  .top-info-preview-wrapper img {
    right: 0;
    width: 200%;
  }
}

@media (max-width: 600px) {
  .top-wrapper {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .top-container {
    display: flex;
  }

  .top-info {
    padding-top: 20px;
  }

  .top-info-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #151717;
    margin-bottom: 20px;
    padding-right: 25px;
  }

  .top-info-title {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #151717;
  }

  .top-info-preview-wrapper {
    padding: 20px 0;
    text-align: center;
  }

  .top-info-preview-wrapper img {
    /* right: -50px; */
    width: 100%;
  }
}
