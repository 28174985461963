.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  z-index: 6;
}

.header-logo {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.header-logo img {
  width: 100%;
}

.logo-name {
  font-size: 34px;
  font-weight: 800;
  line-height: 44px;
  color: #3CB6C1;
  letter-spacing: 2px;
}

.header-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-navigation {
  padding-right: 50px;
  display: flex;
  align-items: center;
}

.header-navigation-item {
  display: block;
  margin-right: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #151717;
  transition: 0.3s;
}

.header-navigation-item-active {
  display: block;
  margin-right: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #3cb6c1;
  transition: 0.3s;
}

.header-navigation-item:hover {
  color: #3cb6c1;
}

.header-button {
  padding: 15px 75px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: white;
  background-color: #3cb6c1;
  border: none;
  border-radius: 80px;
  cursor: pointer;
}

.burger-menu {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.burger-menu-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
}

.burger-menu-container {
  position: relative;
  top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 230px;
  background-color: white;
  box-sizing: border-box;
}

.cross-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.burger-menu-navigation-item {
  display: block;
  padding: 10px 0;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #151717;
  transition: 0.3s;
}

.burger-menu-navigation-item-active {
  display: block;
  padding: 10px 0;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #3cb6c1;
  transition: 0.3s;
}

.burger-menu-navigation-item:hover {
  color: #3cb6c1;
}

@media (max-width: 1100px) {
  .header-logo {
    width: 125px;
  }

  .header-menu {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .header-navigation {
    padding-right: 0;
    display: flex;
    align-items: center;
  }

  .header-navigation-item {
    display: block;
    margin-right: 26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #151717;
    transition: 0.3s;
  }

  .header-navigation-item:last-child {
    margin-right: 0;
  }

  .header-button {
    padding: 15px 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background-color: #3cb6c1;
    border: none;
    border-radius: 80px;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .header-button-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 350px) {
  .header-logo {
    width: 100px;
  }

  .header-button {
    padding: 10px 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: white;
    background-color: #3cb6c1;
    border: none;
    border-radius: 80px;
    cursor: pointer;
  }
}