.features-wrapper {
  position: relative;
  /* height: 100vh; */
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  /* background: linear-gradient(96.21deg, #d3ecf5 4.61%, #dff1f7 93.99%); */
  box-sizing: border-box;
}

.features-wrapper-flower {
  position: absolute;
  left: -400px;
  top: -150px;
  z-index: 1;
}

.features-wrapper-flower img {
  max-width: 100%;
}

.features-wrapper-title {
  font-weight: 700;
  font-size: 44px;
  line-height: 54px;
  color: #151717;
  margin-bottom: 72px;
  z-index: 3;
  position: relative;
}

.features-items {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
  flex-wrap: wrap;
}

.features-item {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
}

.features-item-img {
  display: block;
  margin-bottom: 30px;
  width: 90%;
}

.features-item-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #151717;
  margin-bottom: 15px;
}

.features-item-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #4b5253;
}





@media (max-width: 1920px) {
  .features-item-title {
    max-width: 90%;
    text-align: center;
  }


  .features-items .features-item:nth-child(3) .features-item-title {
    max-width: 50%;
  }
}

@media (max-width: 1240px) {
  .features-items .features-item:nth-child(3) .features-item-title {
    max-width: 80%;
  }

  .features-item-title {
    max-width: 100%;
    text-align: center;
  }
}

@media (max-width: 930px) {



  .features-wrapper-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    color: #151717;
    margin-bottom: 72px;
  }

  .features-item {
    flex-basis: 50%;
    margin-bottom: 50px;
    padding: 0;
  }

  .features-item-img {
    margin-bottom: 20px;
  }

  .features-item-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #151717;
    margin-bottom: 10px;
    max-width: 80%;
  }

  .features-items .features-item:nth-child(3) .features-item-title {
    max-width: 50%;
  }

  .features-item-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #151717;
  }
}

@media (max-width: 750px) {
  .features-item {
    flex-basis: 100%;
    padding: 0 20px;
    flex-basis: 100%;
    padding: 0;
  }

  .features-item-img {
    width: 100%;
  }
}

@media (max-width: 390px) {
  .features-item {
    padding: 0 20px;
  }

  .features-item-img {
    width: 100%;
  }
}